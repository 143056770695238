import { useDispatch, useSelector } from "react-redux";
import { selectSearchFilters } from "../../redux/selectors";
import css from "./Categories.module.css";
import { updateFilters } from "../../redux/cvs/cvsSlice";
import { useState } from "react";

const categories = [
  "IT, Computers, Internet",
  "Administration and Management",
  "Accounting and Auditing",
  "Hotel and Restaurant Business, Tourism",
  "Design, Art and Entertainment",
  "Sports, Fitness, and Beauty",
  "Culture, Music and Show Business ",
  "Logistics, Transportation, and Warehousing",
  "Marketing and Advertising",
  "Medicine and Pharmaceuticals",
  "Real Estate",
  "Science, Education, and Translation",
  "Security and Protection",
  "Working specialties and Production",
  "Media, Journalism and Mass Media",
  "Insurance",
  "Construction and Architecture",
  "Top Management, Directors",
  "Sales, Purchases",
  "Trade and Retail",
  "Transportation, Automotive",
  "HR, Business Coaches",
  "Economics and Finance",
  "Law and Legal Affairs",
  "Agriculture and Agribusiness",
  "Telecommunications and Communication",
];

const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  let i = 0;
  while (i < arr.length) {
    chunks.push(arr.slice(i, i + chunkSize));
    i += chunkSize;
  }
  return chunks;
};

export const Categories = () => {
  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => setShowAll(!showAll);
  const chunks = showAll ? chunkArray(categories, 7) : [categories.slice(0, 7)];

  const handleCategoriesChange = (event) => {
    const { value, checked } = event.target;
    const updatedCategories = checked
      ? [...searchFilters.categories, Number(value)]
      : searchFilters.categories.filter(
          (category) => category !== Number(value)
        );
    dispatch(updateFilters({ categories: updatedCategories }));
  };

  return (
    <>
      <div
        className={`${css.categoriesContainer} ${
          showAll ? css.gridLayout : ""
        }`}
      >
        {chunks.map((chunk, columnIndex) => (
          <div key={columnIndex} className={css.column}>
            {chunk.map((category, localIndex) => {
              const globalIndex =
                columnIndex * chunks[0].length + localIndex + 1; // Calculate unique index
              return (
                <div key={globalIndex} className={css.categoriesItemContainer}>
                  <label className={css.labelCheckbox}>
                    <input
                      type="checkbox"
                      id={globalIndex}
                      value={globalIndex}
                      name="categories"
                      checked={searchFilters.categories.includes(globalIndex)}
                      onChange={handleCategoriesChange}
                      className={css.checkbox}
                    />
                    {category}
                  </label>
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <button type="button" onClick={toggleShowAll} className={css.showButton}>
        {showAll ? "Show less options" : "Show all options"}
      </button>
    </>
  );
};
